<script setup lang="ts">
const props = defineProps<{ rule: any }>()

const isBlack = computed(() => props.rule.name.toLowerCase().includes('black'))
</script> 

<template>
  <span class="py-0 px-3 h-7 items-center border font-bold text-base inline-flex shadow w-auto" :class="{
    'bg-green-100 text-green-700 border-green-500/30 rounded-sm': !isBlack, 
    'bg-black text-white': isBlack,
  }">
    {{ rule.name }}
  </span>
</template>
